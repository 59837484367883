<template>
  <div>合作伙伴</div>
</template>

<script>
export default {
  created() {
    this.$router.push({
      path: '/home/合作伙伴',
      params: {
        id: "合作伙伴"
      }
    })
  }
}
</script>

<style>

</style>